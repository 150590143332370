import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import DatadogHandler from 'utils/datadog';
import Text from './Text';
import Loader from './Loader';

export default function LazyLoadRemoteComponent({
  timeout = 5000,
  hideFreshChat = true,
  loadModule = () => Promise.reject(new Error('Missing loadModule')),
}) {
  const [Component, setComponent] = useState(null);
  const [error, setError] = useState(null);
  const loadRemote = useCallback(() => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(
        () => reject(new Error('Timeout exceeded for loading remote module')),
        timeout
      );
      loadModule?.()
        .then(m => {
          clearTimeout(timer);
          resolve(m);
        })
        .catch(err => {
          DatadogHandler.sendLog(err, {}, 'error');
          clearTimeout(timer);
          reject(err);
        });
    });
  }, [timeout, loadModule]);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const { default: P } = await loadRemote();
        setComponent(() => P);
      } catch {
        setError(true);
      }
    };

    loadComponent();
  }, []);

  useEffect(() => {
    if (hideFreshChat && window.fcWidget) {
      window.fcWidget.hide?.();
      window.fcWidget.destroy?.();
    }
  }, [hideFreshChat]);

  if (!Component) {
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {error ? (
          <Grid container className="w3-center">
            <Grid item xs={12}>
              <Text h2>Oops! Something went wrong.</Text>
            </Grid>
            <Grid item xs={12} className="w3-margin-top">
              <Text>Please refresh the page or try again later</Text>
            </Grid>
            <Grid item xs={12} className="w3-margin-top ">
              <Text>Contact the support team if problem still persists</Text>
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </Box>
    );
  }
  return <Component />;
}
